/** @format */

import { isrValue, ivaValue } from "./sistem";

export const removeIVAFromPrice = (
  price: number,
  isMoral: boolean | undefined
): number => {
  if (isMoral) {
    const res: number = price / (1 + (ivaValue - isrValue));
    return res;
  }
  const res: number = price / (1 + ivaValue);
  return res;
};
