import { ApiResponse } from "../interfaces/apiResponseInterface";
import { ShippingCost } from "../interfaces/shippingCostType";
import axiosTeethTab from "./apiCall";

type ShippingCostApi = {
  getShippingCosts: () => Promise<ApiResponse<ShippingCost[]>>;
  saveShippingCost: (
    shippingCost: ShippingCost
  ) => Promise<ApiResponse<ShippingCost>>;
  deleteShippingCost: (
    shippingCost: ShippingCost
  ) => Promise<ApiResponse<ShippingCost>>;
};

const shippingCostApi: ShippingCostApi = {
  getShippingCosts: async () => {
    const endpoint = "shipping-costs";
    return await axiosTeethTab.get<ShippingCost[]>(endpoint);
  },
  saveShippingCost: async (shippingCost) => {
    const endpoint = shippingCost._id
      ? `shipping-costs/${shippingCost._id}`
      : "shipping-costs";
    return await axiosTeethTab.post<ShippingCost>(endpoint, shippingCost);
  },
  deleteShippingCost: async (shippingCost) => {
    const endpoint = `shipping-costs/${shippingCost._id}/disable`;
    return await axiosTeethTab.post<ShippingCost>(endpoint, shippingCost);
  },
};

export default shippingCostApi;
