import { InputHTMLAttributes, useMemo } from "react";
import SelectInput, {
  OptionType,
} from "../../../components/common/Inputs/SelectInput";
import { FormikErrors, FormikProps } from "formik";
import { OrderInterface } from "../../../interfaces/orderInterface";
import { ShippingCost } from "../../../interfaces/shippingCostType";

export type ShippingCostSelectorProps = Omit<
  InputHTMLAttributes<HTMLSelectElement>,
  "value" | "onChange"
> & {
  label: string;
  error?: FormikErrors<FormData>;
  formik: FormikProps<OrderInterface>;
  formKey: keyof OrderInterface;
  value?: string;
  onChange: (value?: ShippingCost) => void;
  shippingCosts: ShippingCost[];
};

const ShippingCostSelector = ({
  formik,
  label,
  formKey,
  onChange,
  value,
  shippingCosts,
}: ShippingCostSelectorProps) => {
  const options: OptionType[] = useMemo(() => {
    return shippingCosts.map(
      (shippingCost) =>
        ({
          label: shippingCost.name,
          value: shippingCost._id,
        } as OptionType)
    );
  }, [shippingCosts]);

  const handleOnChange: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    const selectedOption = shippingCosts.find(
      (option) => option._id === event.target.value
    );
    onChange(selectedOption);
    formik.setFieldValue(formKey, selectedOption);
  };

  return (
    <SelectInput
      label={label}
      options={options}
      value={value}
      onChange={handleOnChange}
      defaultOptionLabel="Sin costo de envío"
    />
  );
};

export default ShippingCostSelector;
