import * as Yup from "yup";

const shippingCostSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Nombre demaciado corto")
    .required("Campo requerido"),
  price: Yup.number().required("Campo requerido"),
  cost: Yup.number().required("Campo requerido"),
  order: Yup.number(),
});

export default shippingCostSchema;
