import { useEffect, useMemo, useState } from "react";
import Button from "../../components/common/Buttons/Button";
import Title from "../../components/common/Title";
import Modal from "../../components/common/Modal";
import SelectFilter from "../../components/common/Inputs/SelectFilter";
import useDealerStore from "../../stores/useDealerStore";
import useOrderStore from "../../stores/useOrderStore";
import { OrderInterface } from "../../interfaces/orderInterface";
import {
  ColumnInterface,
  ExtraDataInterface,
} from "../../interfaces/customTableInterface";
import CustomTable from "../../components/common/Table/CustomTable";
import orderStatusList, {
  OrderStatusInterface,
  orderStatusOptionList,
} from "../../constants/orderStatus";
import { FormikHelpers, useFormik } from "formik";
import orderSchema from "../../schemas/orderSchema";
import SelectInput, {
  OptionType,
} from "../../components/common/Inputs/SelectInput";
import ProductsForm from "./components/ProductsForm";
import Divider from "../../components/Divider";
import Checkbox from "../../components/common/Inputs/Checkbox";
import { getFullDateFormat } from "../../utils/dateFormater";
import { useIsAuth } from "../../utils/useAuth";
import { UNAUTHORIZED_ERROR } from "../../constants/apiErrors";
import formatToMoney from "../../utils/formatToMoney";
import AddressSelector from "./components/AddressSelector";
import {
  AddressesDataInterface,
  DealerInterface,
} from "../../interfaces/dealerInterface";
import Loading from "../../components/Loading";
import paymentTypes from "../../constants/paymentTypes";
import TextArea from "../../components/common/Inputs/TextArea";
import { MoralTaxRegime } from "../../constants/sistem";
import Tabs from "../../components/common/Tabs/TabContainer";
import Tab from "../../components/common/Tabs/Tab";
import Payments from "./components/Payments";
import CustomInput from "../../components/common/Inputs/CustomInput";
import { paymentMethods } from "../../constants/paymentMethods";
import Pagination from "../../components/common/Pagination";
import { FaSistrix, FaBan } from "react-icons/fa";
import ShippingCostSelector, {
  ShippingCostSelectorProps,
} from "./components/ShippingCostSelector";
import useShippingCosts from "./hooks/useShippingCosts";

const columns: ColumnInterface<OrderInterface>[] = [
  {
    key: "orderNumberFormated",
    label: "No. Orden",
  },
  {
    key: "dealerId",
    label: "Distribuidor",
  },
  {
    key: "status",
    label: "Status",
  },
  {
    key: "total",
    label: "Total",
    isMoney: true,
  },
  {
    key: "totalPayed",
    label: "Saldo",
    isMoney: true,
  },
  {
    key: "requiresInvoice",
    label: "Requiere factura",
    isBoolean: true,
  },
  {
    key: "orderDate",
    label: "Fecha de Orden",
    isDate: true,
  },
];

const formDataInit: OrderInterface = {
  dealerId: "",
  status: 0,
  orderDate: new Date(),
  orderNumber: 0,
  orderNumberFormated: "",
  products: [],
  subTotal: 0,
  discount: 0,
  iva: 0,
  isr: 0,
  total: 0,
  requiresInvoice: false,
  paymentMethod: "",
  paymentType: "",
  payments: [],
};

const pageSizeOptions: OptionType[] = [
  {
    label: "10",
    value: "10",
  },
  {
    label: "20",
    value: "20",
  },
  {
    label: "50",
    value: "50",
  },
];

const paymentStatusList: OptionType[] = [
  {
    label: "Pendiente de pago",
    value: "1",
  },
  {
    label: "Por saldar",
    value: "2",
  },
  {
    label: "Pagado",
    value: "3",
  },
];

const OrdersPage = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [dealerAddresses, setDealerAddresses] = useState<
    AddressesDataInterface[]
  >([]);
  const [selectedDealer, setSelectedDealer] = useState<DealerInterface>();

  const { logout } = useIsAuth();
  const { dealers, getData: getDealers } = useDealerStore();

  // Formik init
  const formik = useFormik<OrderInterface>({
    initialValues: formDataInit,
    validationSchema: orderSchema,
    onSubmit: (
      values: OrderInterface,
      formikHelpers: FormikHelpers<OrderInterface>
    ) => {
      saveData(values);
      formikHelpers.setSubmitting(false); // You can perform additional actions here if needed
      formikHelpers.resetForm();
      handleOnClose();
    },
  });

  const { shippingCosts, selectedShippingCost, setSelectedShippingCost } =
    useShippingCosts({ formik, modalIsOpen: showModal });

  const {
    loading,
    orders,
    paginationData,
    filter,
    setFilter,
    clearFilter,
    pagination,
    setPagination,
    getData,
    saveData,
    error,
    resetError,
  } = useOrderStore();

  const isTaxRegimeMoral = useMemo(() => {
    return (
      selectedDealer && selectedDealer.invoiceData?.taxRegime === MoralTaxRegime
    );
  }, [selectedDealer]);

  useEffect(() => {
    getDealers();
  }, []);

  useEffect(() => {
    getData(filter, pagination);
  }, [pagination, filter]);

  useEffect(() => {
    if (error === UNAUTHORIZED_ERROR) {
      resetError();
      logout();
    }
  }, [error]);

  useEffect(() => {
    if (
      paginationData.totalPages !== 0 &&
      paginationData.totalPages < pagination.page
    ) {
      setPagination("page", 1);
    }
  }, [paginationData, pagination.page]);

  const extraData: ExtraDataInterface<
    OrderStatusInterface | DealerInterface
  >[] = [
    {
      src: orderStatusList,
      dataType: "status",
    },
    {
      src: dealers,
      dataType: "dealerId",
    },
  ];

  const handleOnClose = () => {
    setShowModal(false);
    formik.resetForm();
  };

  const handleEdit = (order: OrderInterface) => {
    setShowModal(true);
    formik.setValues({ ...formik.values, ...order });
    getDealerAddresses(order.dealerId);
    const dealer = dealers.find((x) => x._id === order.dealerId);
    setSelectedDealer(dealer);
  };

  const handleDealerOnChange = (dealerId: string) => {
    let specialDiscount = undefined;
    if (dealerId) {
      const dealer = dealers.find((x) => x._id === dealerId);
      specialDiscount = dealer?.specialDiscount;
      setSelectedDealer(dealer);
    } else {
      setSelectedDealer(undefined);
    }
    formik.setFieldValue("dealerId", dealerId);
    formik.setValues({ ...formik.values, dealerId, specialDiscount });
    getDealerAddresses(dealerId);
  };

  const handleNewOnClick = async () => {
    setShowModal(true);
  };

  const getDealerAddresses = (dealerId: string) => {
    const dealer = dealers.find((x) => x._id === dealerId);
    if (dealer) setDealerAddresses(dealer?.addresses);
  };

  const handleRequiresInvoiceOnChange = () => {
    const nValue = formik.values.requiresInvoice ? false : true;
    const defaultFiscalAddress = dealerAddresses.find(
      (x) => x.isFiscalAddresses
    );
    formik.setValues(
      {
        ...formik.values,
        requiresInvoice: nValue,
        fiscalAddress: defaultFiscalAddress?._id,
      },
      false
    );
    formik.setFieldValue("requiresInvoice", nValue);
  };

  const onPageChange = (pageNumber: number) => {
    if (pageNumber !== paginationData.currentPage) {
      setPagination("page", pageNumber);
    }
  };

  const handleFilter = () => {
    getData(filter, pagination);
  };
  const handleClearFilter = () => {
    clearFilter();
  };

  const handleShippingCostOnChange: ShippingCostSelectorProps["onChange"] = (
    value
  ) => {
    setSelectedShippingCost(value?._id);
  };
  return (
    <div>
      <Title title="Pedidos" />
      <div className="grid grid-cols-2 lg:grid-cols-5 xl:grid-cols-7 gap-4 md:items-end">
        <SelectFilter
          label="Distribuidor"
          data={dealers}
          dataKey={"_id"}
          dataLabel={"name"}
          selected={filter.dealerId ? filter.dealerId : ""}
          onSelect={(value) => setFilter("dealerId", value)}
        />
        <SelectInput
          label="Estatus"
          options={orderStatusOptionList}
          value={filter.statusId ? filter.statusId : ""}
          onChange={(e) => setFilter("statusId", e.target.value)}
        />
        <SelectInput
          label="Estatus de pago"
          options={paymentStatusList}
          value={filter.paymentStatus ? filter.paymentStatus : ""}
          onChange={(e) => setFilter("paymentStatus", e.target.value)}
        />
        <div className="mb-2">
          <CustomInput
            label="No. Orden"
            type="text"
            value={filter.orderNumber}
            onChange={(e) => setFilter("orderNumber", e.target.value)}
          />
        </div>
        <div>
          <Button
            size="medium"
            className="mb-2 mr-2"
            onClick={() => handleFilter()}
            color="primary"
          >
            <FaSistrix />
          </Button>
          <Button
            size="medium"
            className="mb-2"
            onClick={() => handleClearFilter()}
            color="secondary"
          >
            <FaBan />
          </Button>
        </div>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="flex items-end justify-between">
            <div className="w-32">
              <label className="block font-medium mr-2">Ver</label>
              <SelectInput
                options={pageSizeOptions}
                value={pagination.pageSize}
                onChange={(e) => {
                  setPagination("pageSize", parseInt(e.target.value));
                }}
              />
            </div>
            <Button
              size="medium"
              className="mb-2"
              onClick={handleNewOnClick}
              color="primary"
            >
              Nuevo
            </Button>
          </div>
          <CustomTable
            tableId="order-table"
            data={orders}
            columns={columns}
            onEdit={handleEdit}
            extraData={extraData}
            downloadPDF={true}
            paymentStatus={true}
            customRender={(key, item) => {
              if (key === "totalPayed") {
                const totalPayed = item?.totalPayed ?? 0;
                return formatToMoney(item.total - totalPayed);
              }
            }}
          />
          <Pagination
            totalPages={paginationData.totalPages}
            currentPage={paginationData.currentPage}
            onPageChange={onPageChange}
          />
        </>
      )}
      <Modal
        show={showModal}
        close={handleOnClose}
        submit={formik.handleSubmit}
      >
        <Tabs>
          <Tab title="Datos de la Orden">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <SelectFilter
                label="Distribuidor"
                data={dealers}
                dataKey={"_id"}
                dataLabel={"name"}
                selected={formik.values.dealerId}
                onSelect={handleDealerOnChange}
                error={
                  formik.touched.dealerId &&
                  formik.errors.dealerId &&
                  Array.isArray(formik.errors.dealerId)
                    ? formik.errors.dealerId.join(", ")
                    : formik.errors.dealerId
                }
              />
              <SelectInput
                label="Estatus"
                options={orderStatusOptionList}
                {...formik.getFieldProps("status")}
                error={
                  formik.touched.status &&
                  formik.errors.status &&
                  Array.isArray(formik.errors.status)
                    ? formik.errors.status.join(", ")
                    : formik.errors.status
                }
              />
              <AddressSelector
                label="Dirección de envio"
                formKey="shipmentAddress"
                dataSrc={dealerAddresses}
                formik={formik}
              />
            </div>
            {formik.values._id && (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <b>No. de Orden</b>
                  <p>{formik.values.orderNumberFormated}</p>
                </div>
                <div>
                  <b>{getFullDateFormat(formik.values.orderDate)}</b>
                </div>
              </div>
            )}
            <Divider />
            <ProductsForm
              formik={formik}
              dealers={dealers}
              selectedDealer={selectedDealer}
              isTaxRegimeMoral={isTaxRegimeMoral}
            />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-2">
              <div className="md:order-last">
                <div className="grid grid-cols-2">
                  <p className="font-bold">Subtotal</p>
                  <p>{formatToMoney(formik.values.subTotal)}</p>
                </div>
                <div className="grid grid-cols-2">
                  <p className="font-bold">IVA 16%</p>
                  <p>{formatToMoney(formik.values.iva)}</p>
                </div>
                {isTaxRegimeMoral && (
                  <div className="grid grid-cols-2">
                    <p className="font-bold">ISR 1.25%</p>
                    <p>{formatToMoney(formik.values.isr)}</p>
                  </div>
                )}
                <div className="grid grid-cols-2">
                  <p className="font-bold">TOTAL</p>
                  <p>{formatToMoney(formik.values.total)}</p>
                </div>
              </div>
              <div>
                <CustomInput
                  label="Descuento Especial"
                  type="number"
                  error={
                    formik.touched.specialDiscount &&
                    formik.errors.specialDiscount &&
                    Array.isArray(formik.errors.specialDiscount)
                      ? formik.errors.specialDiscount.join(", ")
                      : formik.errors.specialDiscount
                  }
                  {...formik.getFieldProps("specialDiscount")}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                />
                <ShippingCostSelector
                  label="Costo de envío"
                  formKey="shippingCost"
                  formik={formik}
                  value={selectedShippingCost}
                  onChange={handleShippingCostOnChange}
                  shippingCosts={shippingCosts}
                />
                <SelectInput
                  label="Método de pago"
                  options={paymentMethods}
                  {...formik.getFieldProps("paymentMethod")}
                  error={
                    formik.touched.paymentMethod &&
                    formik.errors.paymentMethod &&
                    Array.isArray(formik.errors.paymentMethod)
                      ? formik.errors.paymentMethod.join(", ")
                      : formik.errors.paymentMethod
                  }
                />
                <SelectInput
                  label="Tipo de pago"
                  options={paymentTypes}
                  {...formik.getFieldProps("paymentType")}
                  error={
                    formik.touched.paymentType &&
                    formik.errors.paymentType &&
                    Array.isArray(formik.errors.paymentType)
                      ? formik.errors.paymentType.join(", ")
                      : formik.errors.paymentType
                  }
                />
                <Checkbox
                  label="Requiere factura?"
                  id={"requiresInvoice"}
                  name="requiresInvoice"
                  onChange={handleRequiresInvoiceOnChange}
                  checked={formik.values.requiresInvoice}
                />
                {formik.values.requiresInvoice && (
                  <AddressSelector
                    label="Dirección fiscal"
                    formKey="fiscalAddress"
                    dataSrc={dealerAddresses}
                    formik={formik}
                  />
                )}
              </div>
            </div>
            <TextArea
              label="Notas"
              {...formik.getFieldProps("notes")}
              onChange={(e) => {
                formik.handleChange(e);
              }}
            />
          </Tab>
          <Tab title="Pagos">
            <Payments formik={formik} />
          </Tab>
        </Tabs>
      </Modal>
    </div>
  );
};

export default OrdersPage;
