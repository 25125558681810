import React, { useEffect, useMemo, useState } from "react";
import Button from "../../components/common/Buttons/Button";
import Modal from "../../components/common/Modal";
import Title from "../../components/common/Title";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import CustomInput from "../../components/common/Inputs/CustomInput";
import {
  AddressesDataInterface,
  DealerInterface,
} from "../../interfaces/dealerInterface";
import { ColumnInterface } from "../../interfaces/customTableInterface";
import useDealerStore from "../../stores/useDealerStore";
import CustomError from "../../components/common/CustomError";
import Tabs from "../../components/common/Tabs/TabContainer";
import Tab from "../../components/common/Tabs/Tab";
import Addresses from "./components/Addresses";
import CustomTable from "../../components/common/Table/CustomTable";
import SelectInput, {
  OptionType,
} from "../../components/common/Inputs/SelectInput";
import dealerSchema from "../../schemas/dealerSchema";
import { useIsAuth } from "../../utils/useAuth";
import { UNAUTHORIZED_ERROR } from "../../constants/apiErrors";
import Loading from "../../components/Loading";

const taxRegimeOptions: OptionType[] = [
  {
    label: "Persona Fisica",
    value: "fisica",
  },
  {
    label: "Persona Moral",
    value: "moral",
  },
  {
    label:
      "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
    value: "regimenActividadesEmpresarialesIngresosPlataformasTecnológicas",
  },
  {
    label: "Régimen Simplificado de Confianza",
    value: "regimenSimplificadoConfianza",
  },
  {
    label: "Actividades empresariales y profesionales",
    value: "actividadesEmpresarialesProfesionales",
  },
];

const formDataInit: DealerInterface = {
  name: "",
  phoneNumber: "",
  addresses: [],
  invoiceData: {
    taxRegime: "",
    rfc: "",
    businessName: "",
    legalRepresentative: "",
    cfdi: "",
    email: "",
  },
};

const DealerPage = () => {
  const { logout } = useIsAuth();
  const {
    loading,
    dealers: _dealers,
    getData,
    saveData,
    error,
    resetError,
  } = useDealerStore();
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (error === UNAUTHORIZED_ERROR) {
      resetError();
      logout();
    }
  }, [error]);

  const dealers = useMemo(() => {
    return _dealers.map((item) => {
      const addresses = item?.addresses;
      if (!addresses?.length) return { ...item, state: "", city: "" };

      const fiscalAddress = addresses.find(
        (address) => address.isFiscalAddresses
      );
      const address = fiscalAddress || addresses[0];
      return {
        ...item,
        state: address.state,
        city: address.city,
      };
    });
  }, [_dealers]);

  const columns: ColumnInterface<(typeof dealers)[0]>[] = useMemo(() => {
    return [
      {
        key: "dealerId",
        label: "No.",
      },
      {
        key: "name",
        label: "Nombre",
      },
      {
        key: "addresses",
        label: "Ciudad / Estado",
      },
      {
        key: "state",
        label: "Estado",
      },
      {
        key: "city",
        label: "Ciudad",
      },
    ];
  }, [dealers]);

  const formik = useFormik<DealerInterface>({
    initialValues: formDataInit,
    validationSchema: dealerSchema,
    onSubmit: (
      values: DealerInterface,
      formikHelpers: FormikHelpers<DealerInterface>
    ) => {
      saveData(values);
      formikHelpers.setSubmitting(false); // You can perform additional actions here if needed
      formikHelpers.resetForm();
      handleOnClose();
    },
  });
  const handleOnClose = () => {
    setShowModal(false);
    formik.resetForm();
  };

  const handleEdit = (dealer: DealerInterface) => {
    setShowModal(true);
    formik.setValues(dealer);
  };

  return (
    <div>
      <Title title="Distribuidores" />
      <div className="flex justify-end">
        <Button
          size="medium"
          onClick={() => setShowModal(true)}
          color="primary"
        >
          Nuevo
        </Button>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <CustomTable
          tableId="dealers-table"
          data={dealers}
          columns={columns}
          onEdit={handleEdit}
          customRender={(key, item) => {
            if (key === "addresses") {
              const addresses = item?.addresses;
              if (!addresses?.length) return "";

              const fiscalAddress = addresses.find(
                (address) => address.isFiscalAddresses
              );
              const address = fiscalAddress || addresses[0];

              return `${address.city}, ${address.state}`;
            }
          }}
        />
      )}
      <Modal
        show={showModal}
        close={handleOnClose}
        submit={formik.handleSubmit}
      >
        <Tabs>
          <Tab title="Datos Generales">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <CustomInput
                label="Nombre Comercial"
                error={
                  formik.touched.name &&
                  formik.errors.name &&
                  Array.isArray(formik.errors.name)
                    ? formik.errors.name.join(", ")
                    : formik.errors.name
                }
                {...formik.getFieldProps("name")}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
              />
              <CustomInput
                label="Teléfono"
                error={
                  formik.touched.phoneNumber &&
                  formik.errors.phoneNumber &&
                  Array.isArray(formik.errors.phoneNumber)
                    ? formik.errors.phoneNumber.join(", ")
                    : formik.errors.phoneNumber
                }
                {...formik.getFieldProps("phoneNumber")}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
              />
              <CustomInput
                label="Correo"
                {...formik.getFieldProps("invoiceData.email")}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
              />
              <SelectInput
                label="Régimen Fiscal"
                options={taxRegimeOptions}
                {...formik.getFieldProps("invoiceData.taxRegime")}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
              />
              <CustomInput
                label="RFC"
                {...formik.getFieldProps("invoiceData.rfc")}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
              />
              <CustomInput
                label="Razon Social"
                {...formik.getFieldProps("invoiceData.businessName")}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
              />
              <CustomInput
                label="CFDI"
                {...formik.getFieldProps("invoiceData.cfdi")}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
              />
              <CustomInput
                label="Representante Legal"
                {...formik.getFieldProps("invoiceData.legalRepresentative")}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
              />
              <CustomInput
                label="Descuento Especial"
                type="number"
                error={
                  formik.touched.specialDiscount &&
                  formik.errors.specialDiscount &&
                  Array.isArray(formik.errors.specialDiscount)
                    ? formik.errors.specialDiscount.join(", ")
                    : formik.errors.specialDiscount
                }
                {...formik.getFieldProps("specialDiscount")}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
              />
            </div>
          </Tab>
          <Tab title="Direcciones">
            <Addresses list={formik.values.addresses} formik={formik} />
          </Tab>
        </Tabs>
        {formik.submitCount != 0 && formik.values.addresses.length == 0 ? (
          <CustomError error="Debes agregar almenos una dirección" />
        ) : null}
      </Modal>
    </div>
  );
};

export default DealerPage;
