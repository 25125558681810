import { useEffect, useMemo, useState } from "react";
import { FormikProps } from "formik";
import { OrderInterface } from "../../../interfaces/orderInterface";
import useShippingCostStore from "../../../stores/useShippingCostStore";

type UseShippingCosts = {
  formik: FormikProps<OrderInterface>;
  modalIsOpen: boolean;
};

const useShippingCosts = (args: UseShippingCosts) => {
  const { formik, modalIsOpen } = args;
  const { shippingCosts, getData } = useShippingCostStore();
  const [selectedShippingCost, setSelectedShippingCost] = useState<string>();

  const formValues = useMemo(() => formik.values, [formik.values]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const selectedOption = shippingCosts.find(
      (option) => option._id === selectedShippingCost
    );
    formik.setFieldValue("shippingCost", selectedOption || undefined);
  }, [selectedShippingCost, shippingCosts]);

  useEffect(() => {
    if (modalIsOpen) {
      const isShippingCostEmpty = !formValues._id && !formValues.shippingCost;

      if (isShippingCostEmpty) {
        const shippingCostDefaultValue = shippingCosts
          .sort((a, b) => {
            if (!a.order) return -1; // `a` comes before `b`
            if (!b.order) return 1; // `b` comes before `a`
            return a.order - b.order; // Normal ascending order comparison
          })
          .find((x) => x.defaultShipping);

        setSelectedShippingCost(shippingCostDefaultValue?._id ?? "");
        return;
      }

      setSelectedShippingCost(formValues.shippingCost?._id ?? "");
    }
  }, [shippingCosts, modalIsOpen, formValues._id]);

  return {
    shippingCosts,
    selectedShippingCost,
    setSelectedShippingCost,
  };
};

export default useShippingCosts;
