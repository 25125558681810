import { useEffect, useState } from "react";
import Button from "../../components/common/Buttons/Button";
import CustomInput from "../../components/common/Inputs/CustomInput";
import Modal from "../../components/common/Modal";
import Title from "../../components/common/Title";
import { FormikHelpers, useFormik } from "formik";
import useShippingCostStore from "../../stores/useShippingCostStore";
import { ColumnInterface } from "../../interfaces/customTableInterface";
import CustomTable from "../../components/common/Table/CustomTable";
import { UNAUTHORIZED_ERROR } from "../../constants/apiErrors";
import { useIsAuth } from "../../utils/useAuth";
import Loading from "../../components/Loading";
import { ShippingCost } from "../../interfaces/shippingCostType";
import shippingCostSchema from "../../schemas/shippingCostSchema";
import Checkbox from "../../components/common/Inputs/Checkbox";

const formDataInit: ShippingCost = {
  name: "",
  cost: null,
  price: null,
  order: null,
  defaultShipping: false,
};

const columns: ColumnInterface<ShippingCost>[] = [
  {
    key: "name",
    label: "Nombre",
  },
  {
    key: "price",
    label: "Precio de envío",
  },
  {
    key: "cost",
    label: "Costo de envío",
  },
  {
    key: "order",
    label: "Orden",
  },
  {
    key: "defaultShipping",
    label: "Default",
    isBoolean: true,
  },
];

const ShippingCostsPage = () => {
  const { logout } = useIsAuth();
  const { loading, shippingCosts, getData, saveData, error, resetError } =
    useShippingCostStore();
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (error === UNAUTHORIZED_ERROR) {
      resetError();
      logout();
    }
  }, [error]);

  const formik = useFormik<ShippingCost>({
    initialValues: formDataInit,
    validationSchema: shippingCostSchema,
    onSubmit: (
      values: ShippingCost,
      formikHelpers: FormikHelpers<ShippingCost>
    ) => {
      saveData(values);
      formikHelpers.setSubmitting(false); // You can perform additional actions here if needed
      formikHelpers.resetForm();
      handleOnClose();
    },
  });

  const handleOnClose = () => {
    setShowModal(false);
    formik.resetForm();
  };

  const handleEdit = (shippingCost: ShippingCost) => {
    setShowModal(true);
    formik.setValues(shippingCost);
  };

  const handleDelete = (shippingCost: ShippingCost) => {};

  return (
    <div>
      <Title title="Costos de envío" />
      <div className="flex justify-end">
        <Button
          size="medium"
          onClick={() => setShowModal(true)}
          color="primary"
        >
          Nuevo
        </Button>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <CustomTable
          tableId="product-table"
          data={shippingCosts}
          columns={columns}
          onEdit={handleEdit}
        />
      )}
      <Modal
        show={showModal}
        close={handleOnClose}
        submit={formik.handleSubmit}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <CustomInput
            label="Nombre de Envío"
            error={
              formik.touched.name &&
              formik.errors.name &&
              Array.isArray(formik.errors.name)
                ? formik.errors.name.join(", ")
                : formik.errors.name
            }
            {...formik.getFieldProps("name")}
            onChange={(e) => {
              formik.handleChange(e);
            }}
          />
          <CustomInput
            label="Orden"
            type="number"
            error={
              formik.touched.order &&
              formik.errors.order &&
              Array.isArray(formik.errors.order)
                ? formik.errors.order.join(", ")
                : formik.errors.order
            }
            {...formik.getFieldProps("order")}
            onChange={(e) => {
              formik.handleChange(e);
            }}
          />
          <CustomInput
            label="Precio"
            type="number"
            error={
              formik.touched.price &&
              formik.errors.price &&
              Array.isArray(formik.errors.price)
                ? formik.errors.price.join(", ")
                : formik.errors.price
            }
            {...formik.getFieldProps("price")}
            onChange={(e) => {
              formik.handleChange(e);
            }}
          />
          <CustomInput
            label="Costo"
            type="number"
            error={
              formik.touched.cost &&
              formik.errors.cost &&
              Array.isArray(formik.errors.cost)
                ? formik.errors.cost.join(", ")
                : formik.errors.cost
            }
            {...formik.getFieldProps("cost")}
            onChange={(e) => {
              formik.handleChange(e);
            }}
          />
          <Checkbox
            label="Costo de envío por defecto"
            id="defaultShipping"
            name="defaultShipping"
            onChange={(e) => {
              formik.setFieldValue(
                "defaultShipping",
                !formik.values.defaultShipping
              );
            }}
            checked={formik.values.defaultShipping}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ShippingCostsPage;
