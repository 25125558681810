import { FormikErrors } from "formik";
import React, { InputHTMLAttributes, forwardRef } from "react";

export type OptionType = {
  label: string;
  value: string | number;
};

type Props = InputHTMLAttributes<HTMLSelectElement> & {
  label?: string;
  error?: FormikErrors<FormData>;
  options: OptionType[];
  inputRef?: React.RefObject<HTMLSelectElement>;
  defaultOptionLabel?: string;
};

const SelectInput = forwardRef<HTMLSelectElement, Props>(
  (
    {
      label,
      error,
      options,
      inputRef,
      defaultOptionLabel = "Selecciona una opción",
      ...inputProps
    },
    ref
  ) => {
    return (
      <div className="mb-2">
        <label className="block font-medium">{label}</label>
        <select
          className={`border-slate-300 rounded p-2 border w-full h-11 ${
            error ? "border-red-500" : ""
          }`}
          ref={inputRef || ref} // Use inputRef or the forwarded ref
          {...inputProps}
        >
          <option value="">{defaultOptionLabel}</option>
          {options.map((x) => {
            return (
              <option key={x.value} value={x.value}>
                {x.label}
              </option>
            );
          })}
        </select>
        {error && <div className="error text-red-500">{<>{error}</>}</div>}
      </div>
    );
  }
);

export default SelectInput;
