import { create } from "zustand";
import { errorNotification, successNotification } from "../utils/notification";
import shippingCostsApi from "../services/shippingCostsApi";
import { ShippingCost } from "../interfaces/shippingCostType";

const shippingCostsInit: ShippingCost[] = [];

type ShippingCostStoreInterface = {
  shippingCosts: ShippingCost[];
  loading: boolean;
  error: string;
  resetError: () => void;
  getData: () => void;
  saveData: (role: ShippingCost) => void;
};

const useShippingCostStore = create<ShippingCostStoreInterface>((set) => ({
  shippingCosts: shippingCostsInit,
  loading: false,
  error: "",
  resetError: () => {
    set({ error: "" });
  },
  getData: async () => {
    try {
      set({ loading: true, error: "" });
      const response = await shippingCostsApi.getShippingCosts();
      if (response.ok && response.data) {
        const { data } = response;
        set({ shippingCosts: data, loading: false });
      } else {
        set({ loading: false });
        errorNotification(
          "Oops! algo salió mal, inténtalo de nuevo más tarde."
        );
      }
    } catch (error) {
      set({ loading: false });
      errorNotification("Oops! algo salió mal, inténtalo de nuevo más tarde.");
    }
  },
  saveData: async (shippingCost: ShippingCost) => {
    try {
      set({ loading: true, error: "" });
      const response = await shippingCostsApi.saveShippingCost(shippingCost);
      // Pendiente ver mejores practicas de backend para guardar y paginar contenido
      if (response.ok && response.data) {
        const { data } = response; // This line retrieves the data from the response object
        set({ loading: false, error: "" });
        successNotification("Datos guardados con éxito!");
        await useShippingCostStore.getState().getData();
      } else {
        set({ loading: false, error: response.problem as string });
        errorNotification(
          "Oops! algo salió mal, inténtalo de nuevo más tarde."
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      errorNotification("Oops! algo salió mal, inténtalo de nuevo más tarde.");
      set({ loading: false });
    }
  },
}));

export default useShippingCostStore;
