import { FormikErrors } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { FaSistrix, FaCaretUp, FaCaretDown } from "react-icons/fa";
import CustomInput from "./CustomInput";

type SelectFilterProps<T> = {
  label: string;
  selected: string;
  data: T[];
  dataKey: keyof T;
  dataLabel: keyof T;
  error?: FormikErrors<FormData>;
  onSelect: (value: string) => void;
};

const iconClasses = "absolute right-4 top-1/2 transform -translate-y-1/2";

const SelectFilter = <T extends {}>({
  label,
  selected,
  data,
  dataKey,
  dataLabel,
  error,
  onSelect,
}: SelectFilterProps<T>) => {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [selectedData, setSelectedValue] = useState<T>();
  const selectFilterRef = useRef<HTMLDivElement | null>(null);
  const [dataFiltered, setDataFiltered] = useState<T[]>([]);
  const [filter, setFilter] = useState<string>("");

  useEffect(() => {
    if (selected) {
      const selectedData = data.find((x) => x[dataKey] == selected);
      setSelectedValue(selectedData);
    } else {
      setSelectedValue(undefined);
    }
  }, [selected]);

  useEffect(() => {
    if (filter) {
      setDataFiltered(
        data.filter((x) => {
          const labelValue = x[dataLabel] as string;
          if (labelValue.toLocaleLowerCase().trim().includes(filter)) {
            return x;
          }
        })
      );
    } else {
      setDataFiltered(data);
    }
  }, [data, filter]);

  useEffect(() => {
    if (showOptions) {
      // Attach event listener when the modal is open
      const handleClickOutside = (event: MouseEvent) => {
        if (
          selectFilterRef.current &&
          !selectFilterRef.current.contains(event.target as Node)
        ) {
          setShowOptions(false);
        }
      };

      window.addEventListener("mousedown", handleClickOutside);

      return () => {
        // Remove the event listener when the modal is closed or component unmounts
        window.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [showOptions]);

  const handleOnSelect = (value: string) => {
    onSelect(value);
    setShowOptions(false);
    setFilter("");
  };

  return (
    <div className="relative" ref={selectFilterRef}>
      <div className="mb-2">
        <label className="block font-medium">{label}</label>
        <button
          type="button"
          onClick={() => setShowOptions(!showOptions)}
          className={`relative border-slate-300 rounded p-2 pr-12 border w-full h-11 whitespace-nowrap overflow-hidden ${
            error ? "border-red-500" : ""
          }`}
        >
          <span className="select-none text-left truncate block">
            {selectedData ? (
              <>{selectedData[dataLabel]}</>
            ) : (
              "Selecciona una opción"
            )}
          </span>
          {showOptions ? (
            <FaCaretUp className={iconClasses} />
          ) : (
            <FaCaretDown className={iconClasses} />
          )}
        </button>
        {showOptions && (
          <div className="absolute w-full bg-white border border-slate-300 z-10">
            <CustomInput
              placeholder="Buscar"
              customClass="px-2 py-1 m-0"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              icon={FaSistrix}
            />
            <ul className="max-h-44 overflow-y-auto">
              {dataFiltered.map((x, i) => (
                <li
                  key={`filter-key-${i}`}
                  className="p-2 cursor-pointer"
                  onClick={() => handleOnSelect(x[dataKey] as string)}
                >
                  <>{x[dataLabel]}</>
                </li>
              ))}
            </ul>
          </div>
        )}
        {error && <div className="error text-red-500">{<>{error}</>}</div>}
      </div>
    </div>
  );
};

export default SelectFilter;
